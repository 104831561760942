import btc from 'cryptocurrency-icons/svg/color/btc.svg'

const Footer = () => {
  return (
    <footer className="bg-white">
      <div className="max-w-7xl mx-auto py-12 px-4 overflow-hidden sm:px-6 lg:px-8">
        <div className="mt-8 flex justify-center space-x-6">
          <img src={btc} className="h-6 w-6" aria-hidden="true" alt="Bitcoin"/>
          <p className="text-gray-400">Donate: 1AbrhvfwNVHZizUNLjTorzQxGt9BLjCPqU</p>
        </div>
        <p className="mt-8 text-center text-base text-gray-400">&copy; 2021 Crypto Shops New Zealand. All rights reserved.</p>
      </div>
    </footer>
  )
}

export default Footer
