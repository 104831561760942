import { initializeApp } from "firebase/app"
import { getAnalytics } from "firebase/analytics"
import Footer from './Footer'
import Table from './Table'
import Hero from './Hero'

const firebaseConfig = {
  apiKey: "AIzaSyA0s-Fna39NB4pi99ZENagcI5eEcOHCySE",
  authDomain: "crypto-shops.firebaseapp.com",
  projectId: "crypto-shops",
  storageBucket: "crypto-shops.appspot.com",
  messagingSenderId: "385208824970",
  appId: "1:385208824970:web:1ac904035363650bdae973",
  measurementId: "G-BZ32K6D580"
}

const app = initializeApp(firebaseConfig)
const analytics = getAnalytics(app)

const App = () => {
  return (
    <>
      <Hero />
      <Table name={'Shops'} analytics={analytics}/>
      <Table name={'Services'} analytics={analytics}/>
      <Footer />
    </>
  )
}

export default App
