const Hero = () => (
  <div className="relative overflow-hidden">
    <div className="relative pt-6 pb-16 sm:pb-24">
      <main className="mt-16 mx-auto max-w-7xl px-4 sm:mt-24">
        <div className="text-center">
          <h1 className="text-4xl tracking-tight font-extrabold text-gray-900 sm:text-5xl md:text-6xl mb-5 md:mb-8 ">
            <span className="text-yellow-500 xl:inline">Crypto</span>&nbsp;
            <span className="xl:inline">Shops New Zealand</span>
          </h1>
          <p className="max-w-md mx-auto text-base text-gray-500 sm:text-lg md:mt-5 md:text-xl md:max-w-3xl">
            Find retailers in New Zealand accepting crypto currency payments.
          </p>
          <div className="mt-5 max-w-md mx-auto sm:flex sm:justify-center md:mt-8">
            <div className="rounded-md shadow">
              <a className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-white bg-yellow-500 hover:bg-yellow-600 md:py-4 md:text-lg md:px-10" href="/register">
                Search
              </a>
            </div>
            <div className="mt-3 rounded-md shadow sm:mt-0 sm:ml-3">
              <a href="mailto:contact@cryptoshops.co.nz?subject=Business registration - Crypto Shops New Zealand" className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-yellow-500 bg-white hover:bg-gray-50 md:py-4 md:text-lg md:px-10">
                Add your business
              </a>
            </div>
          </div>
        </div>
      </main>
    </div>
  </div>
)

export default Hero