import React, { useEffect, useState } from 'react'
import { ChevronRightIcon, StarIcon } from '@heroicons/react/solid'
import { logEvent } from "firebase/analytics"
import base from './Base'

const Table = ({ name, analytics }) => {
  const [rows, setRows] = useState([])
  const [error, setError] = useState()

  useEffect(() => {
    base(name)
      .select({ view: 'Grid view'})
      .firstPage((err, records) => {

        if (err) {
          setError('Could not load shops.')
          return
        }

        const rows = records
          .map(record => record.fields)
          .filter(record => record.active)
          .sort(({featured: x}, {featured: y}) => (x === y)? 0 : x? -1 : 1)

        setRows(rows)
      })
  }, [name])

  useEffect(() => {
    error && console.error(error)
  }, [error])

  const onClickRow = (row) => {
    logEvent(analytics, `click ${row.name.toLowerCase()}`, { ...row })
    window.location.assign(row.website)
  }

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-1">
      <p className="max-w-md text-base text-gray-500 sm:text-lg md:mt-5 md:text-xl md:max-w-3xl py-4" >{name} ({(rows.length > 0 && rows.length) || 'Loading'})</p>
      <div className="bg-white shadow overflow-hidden sm:rounded-md">
        <ul className="divide-y divide-gray-200">
          {rows.map((row) => (
            <li key={row.name}>
              <a onClick={() => onClickRow(row)} href={row.website} className="block hover:bg-gray-50">
                <div className="px-4 py-4 flex items-center sm:px-6">
                  <div className="min-w-0 flex-1 sm:flex sm:items-center sm:justify-between">
                    <div className="truncate">
                      <div className="flex text-sm">
                        <p className="font-medium text-yellow-600">{row.name}</p>
                        <p className="ml-1 flex-shrink-0 font-normal text-gray-500">{row.website}</p>
                        {row.featured && <>&nbsp;<StarIcon className="h-5 w-5 text-gray-400" aria-hidden="true" /></>}
                      </div>
                      <div className="mt-2 flex">
                        <div className="flex items-center text-sm text-gray-500" style={{whiteSpace: "normal"}}>
                          {/*<CalendarIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" aria-hidden="true" />*/}
                          <p>
                            {row.description}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="ml-5 flex-shrink-0">
                    <ChevronRightIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                  </div>
                </div>
              </a>
            </li>
          ))}
        </ul>
      </div>
    </div>
  )
}

export default Table
